import React from 'react';
import styled from 'styled-components';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

enum WhereType {
  Github,
  LinkedIn,
  Other,
}

class Where {
  type?: WhereType;
  label?: string;
  url?: string;
}

const WHERES: Where[] = [
];

const WhereAmI = styled(function ({ className }) {
  return (
    <div className={ className }>
      {
        WHERES.map((where) => {
          let { url } = where;
          return (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {(({ type }) => {
                let out = null;
                switch (type) {
                  case WhereType.Github:
                    out = <FontAwesomeIcon icon={faGithub} />;
                    break;
                  case WhereType.LinkedIn:
                    out = <FontAwesomeIcon icon={faLinkedin} />;
                    break;
                  default:
                }

                if (out !== null) {
                  out = (
                    <span style={{ paddingRight: '.5rem' }}>
                      {out}
                    </span>
                  );
                }

                return out;
              })(where)}
              {where.label || '?'}
            </a>
          );
        })
      }
      <a
        className="client-portal"
        href="https://fzn.braindead.io"
      >
        Client Portal
      </a>
      <a
        className="lets-chat"
        href={['m', 'ai', 'lt', 'o', '', ':', 'en', 'zi', '@', 'brai', 'ndead', '.', 'io'].join('')}
        target="_blank"
        rel="noopener noreferrer"
      >
        Let's Chat
      </a>
    </div>
  );
})`

position: absolute;
top: 0;
right: 0;
display: flex;
flex-direction: row;
padding: .5rem;

> a
{
  margin: .0725rem .125rem;
  padding: .5rem .75rem;
  font-size: 1rem;
  color: #FFFFFF;
  font-family: arial;
  cursor: pointer;
  text-decoration: none;
  border-radius: 3px;

  &:last-child
  {
    margin-right: 0;
  }

  &:hover
  {
    background-color: rgba(255, 255, 255, .075);
  }

  &.client-portal
  {
    border: 1px solid rgba(255, 255, 255, .25);
  }

  &.lets-chat
  {
    border: 1px solid #F073AB;

    &:hover
    {
      background: #F073AB;
      color: #222639;
    }
  }
}

`;

export default WhereAmI;
