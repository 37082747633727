import React from 'react';
import styled from 'styled-components';

const PortfolioLanding = styled(function ({ className }) {
  return (
    <div className={ className }></div>
  );
})`

background: #222639;
width: 1024px;
max-width: 100%;
min-height: 67vh;
margin: 0 auto;

`;

export default PortfolioLanding;