import React from 'react';
import styled from 'styled-components';
import WhereAmI from 'js/components/where-am-i';
import GetTheDetes from 'js/components/get-the-detes';
import { ReactComponent as BraindeadLogoSvg } from 'img/braindead.svg';

let BraindeadLogo = styled(BraindeadLogoSvg)`

display: block;
width: 200px;

`;

const PortfolioHeader = styled(function ({ className }) {
  return (
    <div className={ className }>
      <div className="foreground">
        <WhereAmI />
        <GetTheDetes />
        <div className="introduction">
          <div className="logo-placeholder">
            <BraindeadLogo />
            <div className="text">
              <div className="brand">
                <span className="left">BRAINDEAD</span>&nbsp;
                <span className="right">IO</span>
              </div>
              <div className="tagline">
                dead simple performance
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
})`

display: flex;
height: 33vh;
min-height: 420px;
background-image: linear-gradient(
  to bottom,
  #1D2132 0%,
  #24283b 100%
);
justify-content: center;

.foreground
{
  position: relative;
  display: flex;
  align-items: center;
  width: 1024px;
  height: 100%;
  background: rgba(255, 255, 255, .05);

  .introduction
  {
    font-size: 24px;
    color: rgba(255, 255, 255, .25);
    font-family: arial;
    padding-left: 32px;

    .hello
    {
      color: rgba(255, 255, 255, .5);
    }

    .logo-placeholder
    {
      display: flex;
      flex-direction: row;

      svg
      {
        flex-shrink: 0;
      }

      .text
      {
        padding-left: 1rem;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: flex-start;
        justify-content: center;

        .brand
        {
          font-size: 3rem;
          .left
          {
            color: #44576F;
          }
          .right
          {
            color: #F073AB;
          }
        }

        .tagline
        {
          color: #9FA7B3;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 55px;

    .introduction
    {
      font-size: 20px;
      padding-left: 16px;

      > svg
      {
        width: 130px;
      }
    }
  }
}

`;

export default PortfolioHeader;
