import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import styled, { createGlobalStyle } from 'styled-components';

import PortfolioHeader from 'js/components/portfolio-header';
import PortfolioLanding from 'js/components/portfolio-landing';

const GlobalStyle = createGlobalStyle`

html, body
{
  padding: 0;
  margin: 0;
  background-color: #1D2132;
}

* { box-sizing: border-box; }

`;

const Layout = styled(function ({ className, }) {
  return (
    <div className={className}>
      <GlobalStyle />
      <PortfolioHeader />
      <BrowserRouter>
        <Route default>
          <PortfolioLanding />
        </Route>
      </BrowserRouter>
    </div>
  );
})`

`;

export default Layout;