import React from 'react';
import styled from 'styled-components';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GetTheDetes = styled(function ({ className }) {

  return (
    <div className={ className }>
      <a
        href={['m', 'ai', 'lt', 'o', '', ':', 'en', 'zi', '@', 'brain', 'dead', '.', 'io'].join('')}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="label">
          Let's chat!
        </span>
        <span className="icon">
          <FontAwesomeIcon icon={faEnvelope} />
        </span>
      </a>
    </div>
  );
})`

display: flex;
flex-direction: column;
position: absolute;
bottom: 0;
right: 0;
padding: .75rem;
width: 280px;
max-width: 100%;

> a
{
  display: flex;
  flow-direction: row;
  border-radius: 5px;
  border: 2px solid rgba(255, 255, 255, .2);
  display: flex;
  padding: .5rem .5rem .5rem .5rem;
  margin: .25rem;
  align-items: center;
  justify-content: center;
  font-family: arial;
  color: #FFFFFF;
  cursor: pointer;
  text-decoration: none;

  &:hover
  {
    background: rgba(255, 255, 255, .1);
    color: #FFFFFF;
  }

  > .icon
  {
    display: flex;
    flex-shrink: 1;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
  }

  > .label
  {
    flex-grow: 1;
    font-weight: 700;
    padding-left: .125rem;

    .details
    {
      font-size: 11px;
    }
  }
}

`;

export default GetTheDetes;
